<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button icon="pi pi-print" @click="printPage()" label="طباعه" />
      </template>

      <template #right>
        <Calendar
          placeholder="من"
          v-model="search.start"
          dateFormat="dd/mm/yy"
        />
        <Calendar
          style="margin-right: 9px;"
          placeholder="الى"
          v-model="search.end"
          dateFormat="dd/mm/yy"
        />
        <Button
          style="margin-right: 9px;"
          class="p-button-success"
          icon="pi pi-search"
          @click="searchAdd()"
          v-tooltip="'بحث'"
        />
        <Button
          style="margin-right: 9px;"
          class="p-button-help"
          icon="pi pi-download"
          @click="exportCSV($event)"
          v-tooltip="'طباعه'"
        />
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      id="print"
      :paginator="true"
      class="p-datatable-customers table table-striped"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100, 500, 1000, 10000]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه توزيع التركيبات
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <!-- <Column selectionMode="multiple" field="id" headerStyle="width: 3em" /> -->

      <!-- <Column field="id" header="رقم العقد" :sortable="true" /> -->
      <Column
        field="contractsId.clientsId.code"
        header="كود العميل"
        :sortable="true"
      />
      <Column
        field="contractsId.clientsId.name"
        header="اسم العميل"
        :sortable="true"
      />
      <Column
        field="contractsId.branchesId.name"
        header="اسم الفرع"
        :sortable="true"
      />
      <Column field="contractsId.date" header="تاريخ العقد" :sortable="true" />
      <Column
        field="id"
        header="الحاله"
        :sortable="true"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          {{ $getStatus(slotProps.data.status) }}
        </template>
      </Column>

      <Column field="createdAt" header="تاريخ الانشاء">
        <template #body="slotProps">
          {{ $durationFormatFull(slotProps.data.createdAt) }}
        </template>
      </Column>

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            :value="[slotProps.data.contractsId]"
            class="p-datatable-customers table table-striped"
          >
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="deliveryDate" header="اقصى تاريخ تسليم" />
            <Column field="address" header="الموقع" />
            <Column field="note" header="ملاحظات" />
          </DataTable>
          <DataTable
            :value="[slotProps.data]"
            v-if="slotProps.data.backDeliveryNote"
            class="p-datatable-customers table table-striped"
          >
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="backDeliveryNote" header="ملاحظات السكيرتاريه" />
          </DataTable>
          <DataTable
            :value="[slotProps.data.materialsId]"
            class="p-datatable-customers table table-striped"
          >
            <template #header>
              بيانات المخازن
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column
              field="id"
              header="تم الطلب"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps">
                <InputSwitch v-model="slotProps.data.orderDone" disabled />
                {{ slotProps.data.orderDoneDate }}
              </template>
            </Column>
            <Column
              field="id"
              header="تم التجهيز"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps">
                <InputSwitch v-model="slotProps.data.editDone" disabled />
                {{ slotProps.data.editDoneDate }}
              </template>
            </Column>
            <Column
              field="id"
              header="تم الشحن"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps">
                <InputSwitch v-model="slotProps.data.chargedDone" disabled />
                {{ slotProps.data.chargedDoneDate }}
              </template>
            </Column>
            <!-- <Column field="updatedAt" header="اخر تاريخ تعديل">
              <template #body="slotProps">
                {{ format_date(slotProps.data.updatedAt) }}
              </template>
            </Column> -->

            <Column field="note" header="ملاحظات المخزن" />
          </DataTable>
          <DataTable
            :value="slotProps.data.contractsId.productsList"
            class="p-datatable-customers table table-striped"
            :rows="10"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 25, 50, 100, 500, 1000, 10000]"
            currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
          >
            <template #header>
              قائمه المنتجات والخدمات
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="productsId.name" header="المنتج" />
            <Column field="itemsId.name" header="الخدمه" />

            <Column
              field="id"
              header="توزيع"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps2">
                <p v-if="slotProps2.data.usersId">
                  {{ slotProps2.data.usersId.name }}
                </p>
                <!-- <Button
                  type="button"
                  v-else
                  @click="
                    toDelivery(
                      slotProps2.data,
                      slotProps.data.contractsId.productsList,
                      slotProps.data.id,
                    )
                  "
                  icon="pi pi-reply"
                  class="p-button-info p-ml-2  p-button-rounded"
                ></Button> -->
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      displayBasic: false,
      expandedRows: [],
      loading: true,

      delivery: {
        usersId: null,
      },
      usersList: [],
      productsListId: null,
      installationsId: null,
      displayBasicToDelivery: false,
      listx: [],
      search: {
        start: null,
        end: null,
      },
    };
  },
  methods: {
    printPage() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('print').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=1024,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(this.$htmlPrint(stylesHtml, prtHtml));

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    searchAdd() {
      if (this.search.start && !this.search.end) {
        const start = new Date(this.search.start).getTime();
        this.list = this.listx.filter((item) => {
          return new Date(item.createdAt).getTime() >= start;
        });
      }

      if (!this.search.start && this.search.end) {
        const end = new Date(this.search.end).getTime();
        this.list = this.listx.filter((item) => {
          return new Date(item.createdAt).getTime() <= end;
        });
      }

      if (this.search.start && this.search.end) {
        const start = new Date(this.search.start).getTime();
        const end = new Date(this.search.end).getTime();
        this.list = this.listx.filter((item) => {
          return (
            new Date(item.createdAt).getTime() >= start &&
            new Date(item.createdAt).getTime() <= end
          );
        });
      }
    },
    toDelivery(data, allList, installationsId) {
      this.productsListId = data.id;

      const countList = allList.filter((el) => el.usersId == null);
      if (countList.length == 1) {
        this.installationsId = installationsId;
      } else {
        this.installationsId = null;
      }

      this.displayBasicToDelivery = true;
      this.$http.get(`items/${data.itemsId.id}`).then((res) => {
        // const list = [...res.data[0].users, ...res.data[0].productsId.users];
        // let uniquList = [
        //   ...new Map(list.map((item) => [item['id'], item])).values(),
        // ];
        this.usersList = res.data.users;
      });
      this.delivery = {
        usersId: null,
      };
    },
    toAddDelivery() {
      this.displayBasicToDelivery = false;
      if (this.installationsId) {
        this.$http
          .put(`installations/${this.installationsId}`, {
            status: 'UnderOperation',
          })
          .then(() => {});
      }

      this.$http
        .put(`productsList/` + this.productsListId, {
          ...this.delivery,
          distributionsDate: new Date(),
        })
        .then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
            this.getData();
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
    },

    getData() {
      this.$http.get(`installations/getAllDistributions2`).then(
        (response) => {
          this.loading = false;
          this.list = response.data;
          this.listx = response.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },
  },
  created() {
    this.getData();
  },
};
</script>
